@import 'reset';
@import 'variables';
@import 'flags';
@import 'primeng';
@import 'primeng_theme';
@import 'primeflex/primeflex.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

html,
body {
  font: $font-weight-regular 16px/1.5 'Poppins', sans-serif;
  background: $fp-background;
  height: 100%;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  margin-bottom: 0.75rem;
}

.bold {
  font-weight: bold;
}

.text-orange {
  color: #eaa023;
}

.background-orange {
  background-color: $fp-orange-light !important;
}

.background-red-light {
  background-color: $fp-red-light !important;
}

.error-box {
  color: $fp-red;
  font-size: small;
}

.tooltip-icon {
  padding: 0 0.2rem;
}

.cursor-default {
  cursor: default;
}

.cursor-text {
  cursor: text;
}

.file-input {
  display: none;
}

.ql-editor strong {
  font-weight: bold;
}

.ql-editor em {
  font-style: italic;
}

.p-autocomplete {
  @extend .force-show-scrollbars;
  max-height: 150px;
  .p-autocomplete-multiple-container {
    overflow: auto;
    padding-bottom: 0;
    align-content: start;
  }
}

.p-autocomplete-token,
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  margin-bottom: 0.25rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  vertical-align: middle;
}

.p-confirm-popup {
  max-width: 80%;
}

@media only screen and (min-width: $md) {
  .p-confirm-popup {
    max-width: 50vw;
  }
}

.p-dropdown .p-dropdown-label {
  padding: 0.3rem 0.5rem;
}

.p-dropdown .p-dropdown-label:focus-visible {
  outline: none;
}

.p-inputgroup-addon {
  padding: 0.3rem 0.5rem;
}

.p-input-filled .p-inputgroup-addon {
  opacity: 0.6;
}

.p-multiselect .p-multiselect-label {
  padding: 0.3rem 0.5rem;
}

.p-autocomplete-dd .p-button.p-button-icon-only {
  padding: 0 0.5rem;
  background: transparent;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-autocomplete-dd .p-autocomplete-input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-autocomplete .p-autocomplete-input:hover + .p-button.p-button-icon-only,
.p-autocomplete .p-autocomplete-input:focus + .p-button.p-button-icon-only,
.p-autocomplete .p-autocomplete-input:focus + i + .p-button.p-button-icon-only {
  border-color: $fp-blue;
}

.p-autocomplete .p-autocomplete-input:focus,
.p-autocomplete .p-button:focus,
.p-inputnumber .p-inputtext:enabled:focus,
.p-inputnumber input:focus + span {
  box-shadow: none;
}
.p-inputwrapper-focus .p-autocomplete,
.p-inputwrapper-focus .p-inputnumber {
  box-shadow: 0 0 0 0.2rem $fp-blue-light;
  border-radius: 3px;
}

.p-autocomplete .p-autocomplete-input:focus + .p-button.p-button-icon-only,
.p-autocomplete .p-autocomplete-input:focus + i + .p-button.p-button-icon-only {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $fp-blue-light;
  clip-path: inset(-0.2rem -0.2rem -0.2rem 0);
  transition: box-shadow 0.2s;
}

.p-inputnumber .p-button.p-inputnumber-button-up,
.p-inputnumber .p-button.p-inputnumber-button-down {
  background: transparent;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-left: 0;
  border-bottom: 0;
  height: 18px;
  border-radius: 0;
}

.p-inputnumber .p-button.p-inputnumber-button-up {
  border-top-right-radius: 3px;
}

.p-inputnumber .p-button.p-inputnumber-button-down {
  border-bottom: 1px solid #ced4da;
  border-top: 0;
  border-bottom-right-radius: 3px;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-inputnumber input:hover + span .p-button.p-inputnumber-button-up,
.p-inputnumber input:hover + span .p-button.p-inputnumber-button-down,
.p-inputnumber input:focus + span .p-button.p-inputnumber-button-up,
.p-inputnumber input:focus + span .p-button.p-inputnumber-button-down {
  border-color: $fp-blue;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  z-index: -1;
}

.disabled-status {
  opacity: 0.6;
}

.p-dropdown.invalid {
  background-color: $fp-red;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #000;
}

.status-badge-list {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.status-badge-list-overflow,
.p-tag-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.notification-badge {
  position: absolute;
  margin-top: 20px;
  margin-left: -10px;
}

.p-tooltip {
  max-width: fit-content;
}

.p-tooltip-text {
  font-size: small;
}

.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #e7e7e7;
  box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  display: none;
  border-top: 1px solid #e7e7e7;
  box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: hsl(0, 0%, 99%);
  width: 16px;
  height: 0px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #c1c1c1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
 to highlight the thumb when the scrollbar track is hovered without some
 JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
 also have to check whether the element has a scrollbar and if so how wide
 it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-avatar.p-avatar-circle {
  overflow: visible !important;
  border-radius: 50%;
}

.p-menuitem-link-active {
  background-color: lightgrey;
}

.p-sidebar-left.p-sidebar-active {
  width: 240px;
}

@media only screen and (max-width: $xl) {
  .p-datatable table {
    table-layout: auto !important;
  }
  .p-column-filter-row {
    min-width: 150px;
    max-width: 200px;
  }

  th {
    max-width: 200px !important;
  }
}

.p-calendar.p-calendar-w-btn .p-button.p-button-icon-only {
  order: -1;
  padding: 0;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-right: 0;
}
.p-calendar.p-calendar-w-btn input:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-calendar.p-calendar-w-btn input:first-child:hover {
  border-left: 1px solid #ced4da;
}

.p-calendar.p-calendar-w-btn input:first-child:hover + .p-button.p-button-icon-only {
  border-color: $fp-blue;
}

.p-calendar.p-calendar-w-btn input:first-child:focus {
  clip-path: inset(-0.2rem -0.2rem -0.2rem 0);
  border-left: 0;
}
.p-calendar.p-calendar-w-btn input:first-child:focus + .p-button.p-button-icon-only {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $fp-blue-light;
  clip-path: inset(-0.2rem 0 -0.2rem -0.2rem);
  transition: box-shadow 0.2s;
  border-color: $fp-blue;
}

.p-orderlist .p-orderlist-controls .p-button {
  background: $fp-orange;
  color: #000;
  border-color: $fp-orange;
}

.p-orderlist .p-orderlist-controls .p-button:hover {
  background: $fp-orange-dark;
}

.p-orderlist .p-orderlist-controls .p-button:focus {
  box-shadow: $fp-orange;
}
