@import '../../node_modules/primeflex/core/variables.scss';

$input-height: 38px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$border-style: 1px solid #ced4da;

$header-height: 80px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 960px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$fp-background: #f8f9fa;
$primary-color: #90afe8;
$fp-blue: #90afe8;
$fp-blue-light: #a6bce3;
$fp-blue-lighter: #e8effa;
$fp-blue-dark: #819dd0;
$fp-blue-night: #0c0958;
$fp-orange-dark: #de9415;
$fp-orange: #eaa023;
$fp-orange-light: #f2ebe5;
$fp-green: #006d5a;
$fp-green-light: #7acabc;
$fp-green-dark: #006251;
$fp-red: #ee5551;
$fp-red-light: #f4cfdb;
$fp-white: #ffffff;
$fp-grey: #d9d9d9;
.fp-background-blue {
  background-color: $fp-blue !important;
}

.fp-font-white {
  color: $fp-white !important;
}

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color-text: #ffffff;
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 3px;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-ring: 0 0 0 0.2rem #a6d5fa;
  --green-500: #{$fp-green-light};
  color-scheme: light;
}
